.rootContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;
  min-height: 100%;
  min-width: 100%;
}

.listContainer {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 25%;
  max-width: 300px;
  height: 100%;
  overflow: auto;
  padding: 20px 6px;
}

.active {
  background-color: var(--color-subtle-primary);
}

.workContainer {
  width: 100%;
  height: 100%;
  overflow: auto;
  border-left: var(--border-tertiary);
}

.workContainer .pane {
  border-radius: inherit;
}