
.root {
  all: unset;
  display: block;
  width: 42px;
  height: 25px;
  background-color: var(--color-subtle-active);
  box-sizing: border-box;
  border-radius: 9999px;
  position: relative;
  box-shadow: 0 2px 10px var(--black-a7);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.root:hover {
  background-color: var(--color-subtle-active-dark);
}

.root:focus {
  /* box-shadow: var(--shadow-control); */
  outline: 3px solid var(--color-subtle-hover);
}

.root[data-state='checked'] {
  background-color: var(--color-primary);
}

.label {
  text-wrap-mode: nowrap;
}

.thumb {
  display: block;
  box-sizing: content-box;
  width: 21px;
  height: 21px;
  background-color: var(--color-background);
  border-radius: 9999px;
  box-shadow: 0 2px 2px var(--black-a7);
  box-shadow: 0px 0px 8px rgba(0, 29, 55, 0.05), 0px 1px 2px rgba(0, 29, 55, 0.15);
  transition: transform 100ms;
  transform: translateX(2px);
  will-change: transform;
}

.thumb[data-state='checked'] {
  transform: translateX(calc(42px - 21px - 2px));
}

.reverse {
  flex-direction: row-reverse;
}

/*
* Sizes
*/
.root.small {
  width: 36px;
  height: 22px;
}
.root.small .thumb {
  width: 18px;
  height: 18px;
}
.root.small .thumb[data-state='checked'] {
  transform: translateX(calc(36px - 18px - 2px));
}