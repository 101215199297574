.scrollable {
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  max-height: calc(100% + 16px + var(--pane-gap)); /* because using shadow padding */
  padding-bottom: var(--pane-gap);
  margin-bottom: calc(var(--pane-gap) * -1);
}

.paneBar {
  min-height: 0;
  padding: 4px 6px;
}

.paneItem.paneItem {
  min-width: 40px;
  text-align: center;
}