/* Status Indicator Dots */
.statusDot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: calc(50% - 5px) calc(50% - 5px);
}

/* Status Colors */
.statusDot.success {
  background-color: #2ecc71;
  /* Green for success/active */
  box-shadow: 0 0 5px #2ecc71;
}

.statusDot.warning {
  background-color: #f39c12;
  /* Orange for warning/caution */
  box-shadow: 0 0 5px #f39c12;
}

.statusDot.error {
  background-color: #e74c3c;
  /* Red for error/critical */
  box-shadow: 0 0 5px #e74c3c;
}

.statusDot.info {
  background-color: #3498db;
  /* Blue for informational */
  box-shadow: 0 0 5px #3498db;
}

.statusDot,
.statusDot.offline {
  background-color: #95a5a6;
  /* Gray for offline/inactive */
  box-shadow: 0 0 5px #95a5a6;
}
