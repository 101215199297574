.square {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: var(--color-text-on-white);
  background-color: var(--color-user-gray-3);
  text-align: center;
  max-height: 100%;
  max-width: 100%;
  font-size: 16px;
  padding: 0;
}

.square > *:not(.indicator) {
  color: inherit;
}

.square.medium {
  width: 40px;
  height: 40px;
}

.indicator {
  /* display: contents; */
  position: absolute;
  top: -5px;
  right: -5px;
}
