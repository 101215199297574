.container {
  flex-grow: 1;
  width: 100%;
}

.container:empty {
  display: none;
}

.stackedProps {
  flex-wrap: wrap;
  flex-grow: 1;
  margin-top: -4px;
}

.superSubtle,
.addInline {
  color: var(--color-text-secondary);
}

.addInline {
  width: auto !important;
}

.available {
  border-radius: 100px;
  padding: 0 8px;
  background-color: var(--color-background-tertiary);
  color: var(--color-text)
}