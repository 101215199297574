.control {
  background: var(--color-background-tertiary);
  width: 100%;
  border-radius: var(--border-radius-small);
  padding: none;
}

.squareButton {
  flex-basis: calc(100%/3);
}

.floatingInput {
  position: absolute;
  bottom: 20px;
  left: 50%;
  width: 300px;
  transform: translateX(-50%);
  padding: 10px 8px;
  border-radius: 200px;
  background-color: var(--color-background);
  box-shadow: var(--shadow-elevation-mid);
  transition: transform 0.3s var(--snappy-bezier), width 0.3s var(--snappy-bezier), box-shadow 0.3s ease-in-out;
  cursor: text;
  border: 1px solid transparent;
}

.floatingInput:hover {
  border: 1px solid var(--color-border-secondary);
}

.floatingInput:hover .input,
.floatingInput .input {
  border: none;
  background: none;
  outline: none;
  height: 100%;
}

.floatingInput.large {
  width: 400px;
  transform: translateX(-50%) translateY(-20vh);
  box-shadow: var(--shadow-elevation-high);
  border: 1px solid var(--color-border-secondary);
}


.center {
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
}

/* Steps Editors */

.numberInput {
  max-width: 120px;
}

.filterMenu {
  padding: 2px;
}

.actionsModal {
  width: 400px;
}



.hoverLink {
  opacity: 0.7;
}

.hoverLink:hover {
  opacity: 1;
}

.variableRow>*:first-child,
.variableRow>*:last-child {
  width: 50%;
  flex-grow: 1;
}