.panel {
  padding: 8px 6px;
  background: var(--color-background-secondary);
}

.whitePanel {
  background: var(--color-background);
}

.panel.bordered {
  border-bottom: var(--border-secondary);
  border-top: var(--border-secondary);
}

.heading {
  font: var(--font-heading-bold);
  color: var(--color-text-heading);
}

.header {
  padding: 10px 16px;
}
.header.bordered {
  border-bottom: var(--border-secondary);
}

.tabsContainer {
  position: relative;
}

.name {
  display: flex;
  flex-direction: row;
  flex-basis: 0;
  column-gap: 6px;
  width: 100%;
  color: var(--color-text-secondary);
}

.nameContainer {
  padding: 20px 14px 0;
}

.inherit {
  font: inherit;
  color: inherit;
  display: inline;
}

.tabsContainer:hover .hoverTabs {
  opacity: 1;
  pointer-events: all;
}

.hoverTabs {
  position: absolute;
  pointer-events: none;
  z-index: 1;
  left: -6px;
  padding-left: 6px;
  top: 0;
  opacity: 0;
  overflow: visible;
  background: var(--color-background);
}

.selectionText {
  margin: 0 6px;
  color: var(--color-primary);
  text-wrap: nowrap;
}

.dropAfter,
.dropBefore {
  position: relative;
}

.dropAfter::after,
.dropBefore::after {
  content: "";
  height: 100%;
  width: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  background: var(--color-primary);
}

.dropAfter::after {
  right: 0;
}

.dropBefore::after {
  left: 0;
}

.dropOn {
  outline: 2px solid var(--color-primary);
}