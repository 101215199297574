/* EngineStyles */ 
.menuProgressBar {
  min-width: 50px;
}

/* Style inclusions making this weaker than it should... */
.flowPreview.flowPreview {
  width: 100%;
  height: 500px;
}

/* Pane */
.timelineContainer {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  column-gap: 10px;
  width: 100%;
  min-height: 400px;
  max-height: fit-content;
}

.timelineList {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  min-width: calc(max(25vw, 200px));
}


.active:active,
.active:hover,
.active {
  border-color: var(--color-primary) !important;
}

/* MenuList */
.detailPopover {
  min-width: 300px;
}

.detailPopover > * {
  padding: 6px 4px;
}

.detailPopover .menu {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: visible;
}